<template>
    <div v-if="!access">
        <NotFound />
    </div>
    <div v-else>
        <div class="pb-2 mb-3 border-bottom">
            <h2>Leave Approval Details</h2>
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                    <router-link :to="{name:'leave-approvals'}">Leave Approvals</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Leave Details</li>
            </ol>
        </nav>
        <b-container fluid>
            <div v-if="api_error">
                <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="danger"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                >{{api_error}}</b-alert>
            </div>
            <div v-if="api_success">
                <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="success"
                    @dismissed="alertDismissCountDown = 0"
                    @dismiss-count-down="alertCountDownChanged"
                >   {{ api_success }}
                </b-alert>
            </div>
            <div class="col-md-12" v-if="leave_approval_details">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title">
                            <h5>Leave Details</h5>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="form-group row"  v-if="leave_approval_details.hasOwnProperty('employee') && leave_approval_details.employee.full_name">
                            <label class="col-md-3">
                                <b>Employee ID:</b>
                            </label>
                            <div class="col-md-9" v-if="leave_approval_details.employee">
                                {{leave_approval_details.employee.full_name}} [IES-{{leave_approval_details.employee.id}}]
                            </div>
                        </div>

                        <div class="row form-group">
                            <label class="col-md-3">
                                <b>From Date:</b>
                            </label>
                            <div class="col-md-9">
                                {{leave_approval_details.from_date}}
                            </div>
                        </div>

                        <div class="row form-group">
                            <label class="col-md-3">
                                <b>To Date:</b>
                            </label>
                            <div class="col-md-9">
                                {{leave_approval_details.to_date}}
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-3">
                                <b>Status:</b>
                            </label>
                            <div class="col-md-9">
                                <span><b-badge :variant="setBadgeColor(leave_approval_details.status)">{{leave_approval_details.status}}</b-badge></span>
                            </div>
                        </div>

                        <div class="form-group row" v-if="leave_approval_details.employee.reporting_to">
                            <label class="col-md-3">
                                <b>Reporting To</b>
                            </label>

                            <div class="col-md-9">
                                {{ leave_approval_details.employee.reporting_to.full_name }} [IES-{{leave_approval_details.employee.reporting_to.id}}]
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-3">
                                <b> Reason:</b>
                            </label>
                            <div class="col-md-9">
                                <pre class="leave-reason">{{leave_approval_details.reason}}</pre>
                            </div>
                        </div>

                        <div class="form-group row" v-if="leave_approval_details.approved_by">
                            <label class="col-md-3">
                                <b> {{leave_approval_details.status.charAt(0).toUpperCase() + leave_approval_details.status.toLowerCase().slice(1)}} By:</b>
                            </label>
                            <div class="col-md-9">
                                <pre class="leave-reason">{{ leave_approval_details.approved.full_name }} [IES-{{leave_approval_details.approved.id}}]</pre>
                            </div>
                        </div>

                        <div class="form-group row" v-if="leave_approval_details.approved_at">
                            <label class="col-md-3">
                                <b>{{leave_approval_details.status.charAt(0).toUpperCase() + leave_approval_details.status.toLowerCase().slice(1)}}  At:</b>
                            </label>
                            <div class="col-md-9">
                                <pre class="leave-reason">{{leave_approval_details.approved_at}}</pre>
                            </div>
                        </div>

                         <div class="form-group row" v-if="leave_approval_details.comment">
                            <label class="col-md-3">
                                <b>Rejected Comment:</b>
                            </label>
                            <div class="col-md-9">
                                <pre class="leave-reason">{{leave_approval_details.comment}}</pre>
                            </div>
                        </div>

                        <div class="form-group row" style="float:right;">
                            <div class="offset-xs-3 col-xs-9" v-if="leave_approval_details.status == 'APPLIED'">
                                <button
                                    type="button"
                                    class="btn btn-success text-right ma-10px"
                                    @click="leaveApprovalInfo(leave_approval_details.id,'approve')"
                                >Approve</button>
                            </div>
                            <div class="offset-xs-3 col-xs-9" v-if="leave_approval_details.status == 'APPLIED'">
                                <button
                                    type="button"
                                    class="btn btn-danger text-right ma-10px"
                                    @click="leaveApprovalInfo(leave_approval_details.id,'reject')"
                                >Reject</button>
                            </div>
                            <div class="offset-xs-3 col-xs-9">
                                <button
                                    type="button"
                                    class="btn btn-secondary ma-10px"
                                    @click="$router.go(-1)"
                                >Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-container>

        <!-- Cancel modal -->
        <b-modal
            :id="leaveApprovalModal.id"
            :title="leaveApprovalModal.title"
            centered
            size="md"
            @hide="resetleaveApprovalModal"
        >
            <p v-html="message" class="float-left"></p>
            <br> <br>
            <label class="float-left" v-if="leaveApprovalModal.value === 'reject'">
                Comment
                <span class="text-danger" style="font-weight:bold;">*</span>
            </label>
            <b-form-textarea
                v-if="leaveApprovalModal.value === 'reject'"
                placeholder="Reject Reason..."
                v-model="comment"
                rows="5"
            ></b-form-textarea>
            <div slot="modal-footer" class="w-100">
                <b-button class="mt-3 float-right" @click="cancel()">No</b-button>
                <b-button
                    v-if="leaveApprovalModal.value === 'reject'"
                    class="mt-3 float-right btn btn-primary"
                    variant="danger"
                    :disabled="!comment"
                    v-on:click="leaveApproval(leaveApprovalModal.leave_approval_id, leaveApprovalModal.status)"
                    style="margin-right:10px;"
                >Yes</b-button>
                <b-button
                    v-else
                    class="mt-3 float-right"
                    variant="danger"
                    v-on:click="leaveApproval(leaveApprovalModal.leave_approval_id, leaveApprovalModal.status)"
                    style="margin-right:10px;"
                >Yes</b-button>
            </div>
        </b-modal>

    </div>
</template>

<script>
import NotFound from "../../errors/NotFound";
import default_error from "../../../assets/js/global";
export default {
    components: {
        NotFound
    },
    data() {
        return {
            api_error: "",
            api_success: "",
            access: true,
            alertDismissCountDown: 0,
            alertDismissSecs: 10,
            leaveApprovalModal: {
                id: 'leave-approval',
                title: "",
                leave_approval_id:null,
                status:"",
                value:""
            },
            comment:"",
            leave_approval_details:null,
            message:"",
            badge_colors:[
                {
                    status: "APPLIED",
                    color:'info'
                },
                {
                    status: "APPROVED",
                    color:'success'
                },
                {
                    status: "REJECTED",
                    color:'danger'
                }
            ],
        };
    },
    mounted() {
        this.access = this.hasAnyPermission(['CAN_APPROVE_LEAVES', 'CAN_APPROVE_ALL_LEAVES']);
        this.getApprovalLeave();
    },
    methods: {
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },

        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },

        leaveApprovalInfo(id, value) {
            if(value == 'reject') {
                this.message = 'Are you sure you want to Reject this Leave?';
                this.leaveApprovalModal.title = `Reject Confirmation`;
                this.leaveApprovalModal.value = value;
            } else {
                this.message = 'Are you sure you want to Approve this Leave?';
                this.leaveApprovalModal.title = `Approve Confirmation`;
                this.leaveApprovalModal.value = value;
            }
            this.leaveApprovalModal.status = value;
            this.leaveApprovalModal.leave_approval_id = id;
            this.$root.$emit("bv::show::modal", this.leaveApprovalModal.id);
        },

        resetleaveApprovalModal() {
            this.leaveApprovalModal.status = "";
            this.message = "";
            this.leaveApprovalModal.title = "";
            this.leaveApprovalModal.leave_approval_id = null;
        },

        leaveApproval(id, status) {
            if (status == 'reject') {
                this.rejectLeave(id);
            } else {
                this.approveLeave(id)
            }
        },

        approveLeave(id) {
            this.startProgressBar();
            this.axios.post(this.$api.approved_leave, {id:id}, this.getAuthHeaders()).then((response) => {
                this.api_error = "";
                this.api_success = response.data.message;
                this.showAlert();
                this.$router.push({
                    name: 'leave-approvals'
                });
                this.cancel();
                this.updateProgressBar(true);
            })
            .catch(err => {
                if (!err.response) {
                    this.api_error = default_error.server_error;
                } else if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                ) {
                    this.api_error = err.response.data.message;
                } else {
                    this.api_error = default_error.server_error;
                    console.error(err.response.data);
                }
                this.api_success="";
                this.updateProgressBar(false);
                this.showAlert();
            });
        },

        rejectLeave(id) {
            this.startProgressBar();
            this.axios.post(this.$api.reject_leave, {id:id,comment:this.comment}, this.getAuthHeaders()).then((response) => {
                this.api_error = "";
                this.api_success = response.data.message;
                this.showAlert();
                this.$router.push({
                    name: 'leave-approvals'
                });
                this.cancel();
                this.updateProgressBar(true);
            })
            .catch(err => {
                if (!err.response) {
                    this.api_error = default_error.server_error;
                } else if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                ) {
                    this.api_error = err.response.data.message;
                } else {
                    this.api_error = default_error.server_error;
                    console.error(err.response.data);
                }
                this.api_success="";
                this.updateProgressBar(false);
                this.showAlert();
            });
        },
        
        getApprovalLeave() {
            this.startProgressBar();
            var query = this.getAuthHeaders();
            query["params"] = {};
            query['params'] = {
                id: this.$route.params.id
            }
            this.axios.get(this.$api.view_leave_approval, query).then(response => {
                this.leave_approval_details = response.data.data;
                this.updateProgressBar(true);
            }).catch(err => {
                if (!err.response) {
                    this.api_error = default_error.server_error;
                } else if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                ) {
                    this.api_error = err.response.data.message;
                } else {
                    this.api_error = default_error.server_error;
                    console.error(err.response.data);
                }
                this.items = [];
                this.updateProgressBar(false);
                this.showAlert();
            });
        },

        cancel() {
            this.$root.$emit("bv::hide::modal", this.leaveApprovalModal.id);
        },

        setBadgeColor(value) {
            let data = this.badge_colors.find(status => status.status == value);
            if (data) {
                return data.color
            }
        },
    }
};
</script>
<style lang="scss">
@import "../../../assets/css/custom.scss";
.leave-reason{
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Noto Sans", Ubuntu, Cantarell,
    "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 0.875rem;
}
</style>